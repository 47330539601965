<template>
  <div>
    <template>
      <h5>Ingressos</h5>
      <div class="d-flex flex-column gap-4">
        <v-card
          v-for="(_ticketBlock, i) in value"
          class="pa-3 rounded-lg"
          :key="i"
          outlined
          elevation="1"
        >
          <div class="d-flex align-center gap-2">
            <h4 class="mx-1 mb-0">{{ i + 1 }}.</h4>
            <v-autocomplete
              v-model="value[i].TicketBlock"
              :items="availableTicketBlocks"
              item-text="name"
              item-value="id"
              return-object
              label="Lote"
              dense
              outlined
              clearable
              hide-details="auto"
              :item-disabled="itemDisabled(i)"
              style="max-width: calc(100% - 98px)"
              :prepend-icon="i ? 'mdi-close' : null"
              @click:prepend="value.splice(i, 1)"
            >
              <template v-slot:selection="data">
                <span v-bind="data.attrs" @click:close="data.select">
                  {{ data.item.name }}
                </span>
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="value[i].quantity"
              label="Quantidade"
              dense
              outlined
              type="number"
              style="max-width: 90px"
              min="1"
              hide-details="auto"
              :rules="[
                (v) => !!v || 'Campo obrigatório',
                (v) => v > 0 || 'Quantidade inválida',
              ]"
            ></v-text-field>
          </div>
          <v-text-field
            v-if="value[i].TicketBlock?.id === 'create'"
            label="Nome do lote"
            v-model="value[i].value"
            outlined
            hide-details="auto"
            class="mt-3"
            dense
            :rules="[
              (v) => !!v || 'Campo obrigatório',
              (v) =>
                (v || '').includes('{n}') ||
                'Use {n} no nome para preenchimento automático',
            ]"
          ></v-text-field>
        </v-card>
        <v-btn
          text
          block
          @click="value.push({ TicketBlock: null, quantity: 1 })"
          :disabled="!valid || value.length >= itemsOptions.length"
        >
          Adicionar lote
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  emits: ["input"],
  data: () => ({
    search: "",
    options: [],
  }),
  computed: {
    availableTicketBlocks() {
      const data = this.itemsOptions.map((block) => ({
        ...block,
        name: `${block.TicketGroup?.name} - ${block?.name}`,
      }));
      data.push({
        id: "create",
        name: "Criar novo item automático",
        value: null,
      });
      return data;
    },
    selectedTicketBlocks() {
      return this.value.map((tb) => tb.TicketBlock?.id);
    },
    valid() {
      const isValid = this.value.every((tb) => {
        if (tb.TicketBlock?.id === "create")
          return tb.value && tb.value.includes("{n}") && tb.quantity > 0;

        return tb.TicketBlock && tb.quantity > 0;
      });
      this.$emit("valid", isValid);
      return isValid;
    },
  },
  methods: {
    itemDisabled(i) {
      return (item) => {
        const selectedIds = this.selectedTicketBlocks;
        return (
          selectedIds.includes(item.id) &&
          i !== selectedIds.indexOf(item.id) &&
          item.id !== "create"
        );
      };
    },
    updateTicketBlock(index) {
      const selectedBlock = this.itemsOptions.find(
        (block) => block.id === this.value[index].TicketBlock?.id
      );
      this.$set(this.value[index], "TicketBlock", selectedBlock);
    },
  },
  props: {
    itemsOptions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
