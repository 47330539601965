<template>
  <v-card elevation="0">
    <template v-if="!error && !!lastUpdate">
      <div class="mt-2 px-2 d-flex align-center">
        <!-- <v-btn
          @click="goToFinancial"
          text
          x-small
          color="primary"
          v-if="hasPermission(2048)"
        >
          Ver financeiro completo
          <v-icon x-small right>mdi-open-in-new</v-icon>
        </v-btn> -->
        <Filters
          v-model="filter"
          class="pr-2"
          :party="party"
          :sellers="sellers"
          :pos-sellers="posSellers"
          :ticket-groups="ticketGroups"
          :sale-links="saleLinks"
        />
        <!-- <v-btn
          color="primary"
          depressed
          small
          :to="`/admin/organization/entrance/${partyId}/management`"
        >
          Ver Entradas
        </v-btn> -->
        <v-spacer />
        <v-btn depressed x-small :disabled="loading" @click="openExport">
          Exportar
          <v-icon right x-small>mdi-file-export</v-icon>
        </v-btn>
        <v-btn
          @click="getReportData"
          icon
          small
          :loading="loading"
          :disabled="blockRefresh"
          :class="loading ? 'mr-2' : ''"
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        <span class="text-caption" v-if="lastUpdate">
          Última atualização: {{ lastUpdate }}
        </span>
      </div>

      <div v-if="party.ChildParties.length">
        <v-chip-group v-model="filterSubParties" color="primary" show-arrows>
          <v-chip small filter :value="null"> Todos </v-chip>
          <v-chip :value="party.id" filter small> Principal </v-chip>
          <v-chip
            v-for="subParty in party.ChildParties"
            :key="subParty.id"
            :value="subParty.id"
            filter
            small
          >
            {{ subParty.name }}
          </v-chip>
        </v-chip-group>
      </div>

      <div class="pa-2">
        <highlights :data="{ party, ...filterReportData }" :loading="loading" />
      </div>

      <cols-organizer md="2" xl="3" :items="computedReports">
        <template v-slot="{ item }">
          <component
            :is="item"
            :id="item"
            :loading="loading"
            :data="{ party, ticketGroups, ...filterReportData }"
          />
        </template>
      </cols-organizer>
    </template>
    <v-alert v-else-if="error" type="error" class="mb-0">
      <v-row align="center">
        <v-col class="grow py-0">
          {{ error }}
        </v-col>
        <v-col class="shrink py-0">
          <v-btn small @click="getReportData" :disabled="blockRefresh">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <template v-else>
      <v-progress-linear indeterminate color="info" class="mt-2" />
      <v-alert type="info" text class="mt-0 mb-0">
        Carregando relatório, por favor aguarde...
        <p v-if="oldEvent" class="mb-0">
          Eventos antigos podem demorar mais para carregar
        </p>
      </v-alert>
    </template>

    <export-modal
      :ticketGroups="ticketGroups"
      :posSellers="posSellers"
      :party="party"
      :sellers="sellers"
      :saleLinks="saleLinks"
      :reportData="this.reportData"
    />
  </v-card>
</template>

<script>
import moment from "moment";
import ORGANIZATION from "@/services/admin/organization";
import TICKET from "@/services/admin/ticket";
import SELLER from "@/services/admin/party/seller";
const PARTYREPORTS = ORGANIZATION.party.reports;

import ExportModal from "./reports/export/ExportModal.vue";
import SalesFromToday from "./reports/SalesFromToday.vue";
import SalesByDay from "./reports/SalesByDay.vue";
import SalesEvolution from "./reports/SalesEvolution.vue";
import SalesByTicketBlock from "./reports/SalesByTicketBlock.vue";
import SalesBySeller from "./reports/SalesBySeller.vue";
import SalesByPaymentType from "./reports/SalesByPaymentType.vue";
import SalesByChannel from "./reports/SalesByChannel.vue";
import RefundedSales from "./reports/RefundedSales.vue";
import PosSales from "./reports/PosSales.vue";
import TicketOfficeSales from "./reports/TicketOfficeSales.vue";
import CourtesySales from "./reports/CourtesySales.vue";
import TableReport from "./reports/TableReport.vue";
import SalesInCalendar from "./reports/SalesInCalendar.vue";
import SalesForMemberships from "./reports/SalesForMemberships.vue";
import SalesPatten from "./reports/SalesPatten.vue";
import ColsOrganizer from "../../global/ColsOrganizer.vue";
import Highlights from "./reports/Highlights.vue";
import Filters from "./reports/Filters.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: true,
    blockRefresh: false,
    error: false,
    filter: {
      courtesy: true,
    },
    filterSubParties: null,
    ticketGroups: [],
    posSellers: [],
    saleLinks: [],
    sellers: [],
    ticketBlockMap: new Map(),
    reports: {
      sales_from_today: function () {
        return this.partyActive;
      },
      sales_patten: true,
      sales_in_calendar: true,
      sales_evolution: true,
      sales_by_ticket_block: true,
      sales_by_payment_type: true,
      // sales_for_memberships: function ({ tickets }) {
      //   return (
      //     this.hasPermission([16, 4194304, 8388608]) &&
      //     tickets.some((t) => !!t.Payment.MembershipFiliation)
      //   );
      // },
      table_report: function ({ tickets }) {
        return tickets.some((t) => !!t.Table);
      },
      pos_sales: true,
      ticket_office_sales: true,
      sales_by_channel: true,
      courtesy_sales: true,
      sales_by_day: true,
      sales_by_seller: true,
      refunded_sales: true,
    },
    reportData: {},
    lastUpdate: null,
    timeout: null,
  }),

  methods: {
    goToFinancial() {
      this.$router.push({
        name: "admin.transactions.party",
        params: { partyId: this.party.id },
      });
    },
    openExport() {
      this.$root.$emit("exportReport");
    },
    async getSellers() {
      try {
        this.loading = true;
        const { sellers, posSellers } = await SELLER.getAll(
          this.party.organizationId,
          this.party.id
        ).catch(() => ({ sellers: [], posSellers: [] }));

        this.sellers = sellers.map((s) => ({
          id: s.id,
          name: s.name,
          photo: s.photo,
          type: "user",
        }));

        this.posSellers = posSellers.map((pos) => ({
          id: pos.id,
          type: pos.partyId ? "ticketOffice" : "pos",
          Address: { name: pos.name || pos.Address?.name },
          type: "pos",
        }));
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    async getTicketGroups() {
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id,
          { childrenParty: true }
        );
        this.ticketGroups = response.ticketGroups;
        this.filter.ticketBlock = response.ticketGroups.reduce(
          (acc, tg) => [...acc, ...tg.TicketBlock.map((tb) => tb.id)],
          []
        );

        const tbMap = new Map();
        response.ticketGroups.forEach((tg) => {
          tg.TicketBlock.forEach((tb) => {
            tbMap.set(tb.id, {
              id: tb.id,
              name: tb.name,
              price: tb.price,
              TicketGroup: {
                id: tg.id,
                name: tg.name,
                partytId: tg.partytId,
              },
            });
          });
        });

        this.ticketBlockMap = tbMap;
      } catch (e) {
        console.error(e);
      }
    },
    async getSaleLinks() {
      try {
        this.loading = true;
        const { saleLinks } = await ORGANIZATION.party.saleLink
          .getAll(this.party.organizationId, this.party.id)
          .catch(() => ({ saleLinks: [] }));

        this.saleLinks = saleLinks;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    async getReport() {
      try {
        this.error = null;
        this.loading = true;
        this.blockRefresh = true;

        const { payments } = await PARTYREPORTS.payments(
          this.party.organizationId,
          this.party.id
        );

        const tickets = [];
        this.reportData.payments = payments.map((p) => {
          const Tickets = p.Ticket.map((t) => {
            const tb = this.ticketBlockMap.get(t.ticketBlockId);
            const ticket = { ...t, TicketBlock: tb, Payment: p };
            tickets.push(ticket);
            return ticket;
          });
          return { ...p, Ticket: Tickets };
        });

        this.reportData.tickets = tickets;

        this.lastUpdate = moment().format("HH:mm:ss");
        this.enableRefresh(10000);
      } catch (e) {
        this.error = e.message || "Erro ao carregar relatório";
        this.enableRefresh(5000);
      } finally {
        this.loading = false;
        this.setTimeout();
      }
    },
    async getReportData() {
      return Promise.all([this.getTicketGroups(), this.getReport()]);
    },
    async setTimeout() {
      this.timeout = setTimeout(() => {
        this.getReportData();
      }, 5 * 60 * 1000);
    },
    enableRefresh(time) {
      setTimeout(() => {
        this.blockRefresh = false;
      }, time);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    partyActive() {
      return moment(this.party?.endDate).isAfter(moment());
    },
    oldEvent() {
      return moment(this.party.date).isBefore(moment().subtract(3, "months"));
    },

    filterReportData() {
      return {
        tickets: this.filterTickets,
        payments: this.filterPayments,
        filter: this.filter,
        raw: this.rawDataSubEventFilter,
      };
    },
    rawDataSubEventFilter() {
      if (!this.filterSubParties) return this.reportData;
      return {
        payments: this.reportData.payments.reduce((acc, p) => {
          const tickets = p.Ticket.filter(
            (t) => t.TicketBlock.TicketGroup.partytId === this.filterSubParties
          );
          if (tickets.length) acc.push({ ...p, Ticket: tickets });
          return acc;
        }, []),
        tickets: this.reportData.tickets.filter(
          (t) => t.TicketBlock.TicketGroup.partytId === this.filterSubParties
        ),
      };
    },
    partyId() {
      return this.$route.params.partyId;
    },
    filterPayments() {
      return this.rawDataSubEventFilter.payments.filter((p) => {
        if (!this.filter.courtesy && p.paymentType === "COURTESY") return false;

        if (this.filter?.saleLink)
          if (p.Ticket[0].saleLinkId !== this.filter.saleLink.id) return false;

        const sellerType = p.PosSession
          ? "pos"
          : p.Ticket[0].Seller?.id
          ? "user"
          : "online";

        const sellerId = p.PosSession?.id || p.Ticket[0].Seller?.id || "online";
        if (
          this.filter?.seller?.length &&
          !this.filter.seller.some(
            (s) => s.id === sellerId && s.type === sellerType
          )
        )
          return false;
        return true;
      });
    },
    filterTickets() {
      return this.filterPayments
        .map(({ Ticket, ...p }) => Ticket.map((t) => ({ ...t, Payment: p })))
        .flat();
    },
    computedReports() {
      if (!this.filterReportData.payments || !this.filterReportData.tickets)
        return [];
      return Object.keys(this.reports).filter((r) => {
        if (typeof this.reports[r] === "function")
          return this.reports[r].bind(this)(this.rawDataSubEventFilter);
        return this.reports[r];
      });
    },
  },
  async mounted() {
    await Promise.all([
      this.getSellers(),
      this.getSaleLinks(),
      this.getTicketGroups(),
    ]);
    this.getReport();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  components: {
    sales_from_today: SalesFromToday,
    sales_by_day: SalesByDay,
    sales_by_ticket_block: SalesByTicketBlock,
    sales_by_seller: SalesBySeller,
    sales_by_payment_type: SalesByPaymentType,
    sales_in_calendar: SalesInCalendar,
    sales_by_channel: SalesByChannel,
    refunded_sales: RefundedSales,
    sales_patten: SalesPatten,
    pos_sales: PosSales,
    ticket_office_sales: TicketOfficeSales,
    courtesy_sales: CourtesySales,
    sales_evolution: SalesEvolution,
    table_report: TableReport,
    sales_for_memberships: SalesForMemberships,
    ColsOrganizer,
    Highlights,
    Filters,
    ExportModal,
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
