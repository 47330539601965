<template>
  <div>
    <v-form v-model="valid" ref="form">
      <div class="table-wrapper">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Lote</th>
                <th
                  v-for="(category, i) in options.categories"
                  style="max-width: 250px"
                  :key="i"
                  class="text-left"
                >
                  {{ category }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tg, index) in options.ticketBlocks" :key="index">
                <td>{{ tg.name }}</td>
                <td
                  class="px-2"
                  style="max-width: 250px"
                  v-for="(category, i) in options.categories"
                  :key="i"
                >
                  <!-- receber o emit do valid aqui chatgpt -->
                  <input-ticketBlock-matriz
                    :autofocus="index === 0 && i === 0"
                    :price="tg.prices[category]"
                    :quantity="tg.quantities[category]"
                    :category="category"
                    @price="handleInputPrice(index, category, $event)"
                    @quantity="handleInputQuantity(index, category, $event)"
                    @valid="handleValid"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-form>
  </div>
</template>

<script>
import InputTicketBlockMatriz from "./InputTicketBlockMatriz.vue";

export default {
  components: {
    InputTicketBlockMatriz,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    valid: false,
  }),
  methods: {
    handleInputChange() {
      this.$emit("valid", this.valid);
      this.$emit("options", this.options);
    },
    handleInputPrice(index, category, value) {
      console.log("index", index, category, value);
      const options = structuredClone(this.options);
      options.ticketBlocks[index].prices[category] = value;
      this.$emit("options", options);
    },
    handleInputQuantity(index, category, value) {
      console.log("index", index, category, value);
      const options = structuredClone(this.options);
      options.ticketBlocks[index].quantities[category] = value;
      this.$emit("options", options);
    },
    handleValid(isValid) {
      this.$emit("valid", isValid); 
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
}
</style>
