<template>
  <v-card outlined :loading="loading" v-intersect="onIntersect">
    <v-card-title class="d-flex justify-space-between pb-0">
      <h6 class="mb-0 lh-1">Forma de Pagamento</h6>
      <v-spacer />
      <v-btn-toggle
        v-if="viewType == 'chart'"
        v-model="display"
        class="ml-2"
        mandatory
      >
        <v-btn small> R$ </v-btn>
        <v-btn small> Qtde </v-btn>
      </v-btn-toggle>
      <v-btn small @click="toggleView" icon class="ml-2">
        <v-icon>mdi-{{ viewType == "table" ? "chart-donut" : "table" }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-select
        dense
        outlined
        v-model="paymentStatus"
        :items="paymentStatusSelectList"
        label="Status do Pagamento"
        hide-details
        class="mb-2"
      />
      <v-alert
        v-if="!chartSeries.length"
        type="warning"
        class="mb-0"
        outlined
        dense
      >
        Nenhum pagamento
        <b>{{ statusTranslate[paymentStatus].toLowerCase() }}</b
        >.
      </v-alert>
      <template v-else>
        <apexCharts
          v-if="viewType == 'chart'"
          type="donut"
          height="300"
          :options="chartOptions"
          :series="chartSeries"
        />
        <v-data-table
          v-else
          dense
          :headers="[
            { text: 'Forma de Pagamento', value: 'paymentType' },
            { text: 'Quantidade', value: 'quantity', align: 'right' },
            { text: 'Valor', value: 'amount', align: 'right' },
          ]"
          :items="tableItems"
          :items-per-page="tableItems.length"
          hide-default-footer
          group-by="paymentMethod"
          sort-by="amount"
          sort-desc
        >
          <template #group.header="{ group }">
            <td colspan="3">
              <b>{{ group }}</b>
            </td>
          </template>
          <template #item.paymentType="{ item }">
            {{
              paymentUtils.paymentType[item.paymentType]?.text ||
              item.paymentType
            }}
          </template>
          <template #item.amount="{ item }">
            {{ item.amount | currency }}
          </template>
          <template #item.quantity="{ item }">
            {{ item.quantity }}
          </template>
        </v-data-table>
      </template>
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import paymentUtils from "@/utils/payment";
import ApexCharts from "vue-apexcharts";

export default {
  components: {
    ApexCharts,
  },
  data: () => ({
    error: null,
    display: 0,
    viewed: false,
    isIntersecting: false,
    paymentStatus: "succeeded",
    viewType: "table",
    paymentUtils: paymentUtils,
    statusTranslate: {
      pending: "Pendente",
      succeeded: "Pago",
      refunded: "Reembolsado",
    },
    status: {
      pending: "pending",
      requires_payment_method: "pending",
      requires_confirmation: "pending",
      requires_action: "pending",
      processing: "pending",
      requires_capture: "succeeded",
      canceled: "canceled",
      refunded: "refunded",
      succeeded: "succeeded",
      rejected: "rejected",
      disputed: "refunded",
    },
  }),
  methods: {
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isIntersecting = true;
        this.viewed = true;
      } else {
        this.isIntersecting = false;
      }
    },
    formatName(key) {
      if (!key) return "";
      const [method, type] = key.split("-");
      const name = this.formatPaymentMethod(method);
      return `${name} • ${paymentUtils.paymentType[type]?.text || type}`;
    },
    formatPaymentMethod(method) {
      return method === "POS"
        ? "POS"
        : method === "OFFLINE"
        ? "Externo"
        : "Online";
    },
    toggleView() {
      console.log(this.viewType);
      this.viewType = this.viewType == "table" ? "chart" : "table";
    },
  },
  computed: {
    paymentStatusSelectList() {
      return Object.keys(this.statusTranslate).map((key) => ({
        text: this.statusTranslate[key],
        value: key,
      }));
    },
    paymentTypes() {
      if (!this.viewed) return {};

      const paymentStatusList = Object.entries(this.status)
        .filter(([key, value]) => value == this.paymentStatus)
        .map(([key, value]) => key);

      return this.data.payments
        .filter((payment) => paymentStatusList.includes(payment.status))
        .reduce((acc, p) => {
          const method = p.paymentMethod === "POS" ? "POS" : p.paymentMethod;

          const paymentType = `${method}-${p.paymentType}`;
          if (!acc[paymentType]) acc[paymentType] = [0, 0];

          acc[paymentType][0] = p.Ticket.reduce(
            (acc, { amount, platformFee }) => acc + amount - platformFee,
            acc[paymentType][0]
          );
          acc[paymentType][1] += p.Ticket.length;

          return acc;
        }, {});
    },
    tableItems() {
      return Object.entries(this.paymentTypes).map(
        ([key, [amount, quantity]]) => {
          const [paymentMethod, paymentType] = key.split("-");
          return {
            paymentMethod: this.formatPaymentMethod(paymentMethod),
            paymentType,
            amount,
            quantity,
          };
        }
      );
    },
    chartSeries() {
      if (!this.paymentTypes) return [];
      return Object.values(this.paymentTypes).map(
        (values) => values[this.display]
      );
    },
    chartOptions() {
      return {
        chart: {
          type: "donut",
          height: 300,
        },
        legend: {
          show: true,
          position: "bottom",
          formatter: (seriesName, opts) => {
            var value = opts.w.globals.series[opts.seriesIndex];
            value = this.display
              ? `${value} venda${value != 1 ? "s" : ""}`
              : value.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                });
            return `${seriesName}: ${value}`;
          },
        },
        fill: {
          type: Object.keys(this.paymentTypes).map((key) =>
            key.split("-")[0] == "POS" ? "pattern" : "solid"
          ),
          pattern: {
            style: "slantedLines",
            width: 8,
            height: 10,
            strokeWidth: 5,
          },
        },
        labels: Object.keys(this.paymentTypes).map((key) => {
          return this.formatName(key);
        }),
        colors: Object.keys(this.paymentTypes).map(
          (key) => paymentUtils.paymentType[key.split("-")[1]]?.color || key
        ),
        dataLabels: { enabled: false },
        yaxis: {
          labels: {
            formatter: (value) =>
              this.display
                ? `${value} ingressos${value != 1 ? "s" : ""}`
                : value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }),
          },
        },
      };
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
