var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[_c('h5',[_vm._v("Ingressos")]),_c('div',{staticClass:"d-flex flex-column gap-4"},[_vm._l((_vm.value),function(_ticketBlock,i){return _c('v-card',{key:i,staticClass:"pa-3 rounded-lg",attrs:{"outlined":"","elevation":"1"}},[_c('div',{staticClass:"d-flex align-center gap-2"},[_c('h4',{staticClass:"mx-1 mb-0"},[_vm._v(_vm._s(i + 1)+".")]),_c('v-autocomplete',{staticStyle:{"max-width":"calc(100% - 98px)"},attrs:{"items":_vm.availableTicketBlocks,"item-text":"name","item-value":"id","return-object":"","label":"Lote","dense":"","outlined":"","clearable":"","hide-details":"auto","item-disabled":_vm.itemDisabled(i),"prepend-icon":i ? 'mdi-close' : null},on:{"click:prepend":function($event){return _vm.value.splice(i, 1)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('span',_vm._b({on:{"click:close":data.select}},'span',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,true),model:{value:(_vm.value[i].TicketBlock),callback:function ($$v) {_vm.$set(_vm.value[i], "TicketBlock", $$v)},expression:"value[i].TicketBlock"}}),_c('v-text-field',{staticStyle:{"max-width":"90px"},attrs:{"label":"Quantidade","dense":"","outlined":"","type":"number","min":"1","hide-details":"auto","rules":[
              (v) => !!v || 'Campo obrigatório',
              (v) => v > 0 || 'Quantidade inválida',
            ]},model:{value:(_vm.value[i].quantity),callback:function ($$v) {_vm.$set(_vm.value[i], "quantity", $$v)},expression:"value[i].quantity"}})],1),(_vm.value[i].TicketBlock?.id === 'create')?_c('v-text-field',{staticClass:"mt-3",attrs:{"label":"Nome do lote","outlined":"","hide-details":"auto","dense":"","rules":[
            (v) => !!v || 'Campo obrigatório',
            (v) =>
              (v || '').includes('{n}') ||
              'Use {n} no nome para preenchimento automático',
          ]},model:{value:(_vm.value[i].value),callback:function ($$v) {_vm.$set(_vm.value[i], "value", $$v)},expression:"value[i].value"}}):_vm._e()],1)}),_c('v-btn',{attrs:{"text":"","block":"","disabled":!_vm.valid || _vm.value.length >= _vm.itemsOptions.length},on:{"click":function($event){return _vm.value.push({ TicketBlock: null, quantity: 1 })}}},[_vm._v(" Adicionar lote ")])],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }