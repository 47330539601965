<template>
  <v-form v-model="valid" ref="form">
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-column gap-3">
          <b>Categorias</b>
          <div
            v-for="(category, index) in options.categories"
            :key="index"
            class="d-flex align-center"
          >
            <v-text-field
              v-model="options.categories[index]"
              placeholder="Ex: Meias, Inteiras, Promocional"
              outlined
              :rules="[
                (v) => !!v || 'Categoria é obrigatória',
                (v) => !hasDuplicateCategory(v, index) || 'Categoria duplicada',
              ]"
              dense
              hide-details="auto"
              class="flex-grow-1"
              @keyup.enter="addCategory"
              :autofocus="index === options.categories.length - 1"
            ></v-text-field>

            <v-btn
              v-if="options.categories.length > 1 && index > 0"
              icon
              @click="removeCategory(index)"
              class="ml-2"
            >
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </div>
          <v-btn small block text color="primary" @click="addCategory">
            <v-icon small left>mdi-plus</v-icon>
            Adicionar categoria
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <div class="d-flex flex-column gap-3">
          <b>Lotes</b>
          <v-card
            v-for="(ticketBlock, index) in options.ticketBlocks"
            :key="'ticketBlock-' + index"
            outlined
            class="w-full pa-3"
          >
            <div class="d-flex justify-space-between gap-3 align-start">
              <v-text-field
                v-model="ticketBlock.name"
                :rules="[(v) => !!v || 'Nome é obrigatório']"
                label="Nome"
                required
                outlined
                dense
              ></v-text-field>
              <v-btn
                v-if="options.ticketBlocks.length > 1"
                @click="removeTicketBlock(index)"
                icon
                color="red"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <div class="d-flex flex-wrap gap-3">
              <v-text-field
                :max="new Date(party.endDate).toISOString().substr(0, 16)"
                v-model="ticketBlock.startDate"
                label="Inicio das vendas"
                type="datetime-local"
                :rules="dateRules.start"
                hide-details="auto"
                outlined
                dense
              />

              <v-text-field
                label="Fim das vendas"
                :max="new Date(party.endDate).toISOString().substr(0, 16)"
                v-model="ticketBlock.endDate"
                dense
                type="datetime-local"
                :rules="dateRules.end(index)"
                hide-details="auto"
                outlined
              />
            </div>
            <v-switch
              v-model="ticketBlock.active"
              label="Lote ativo"
              :messages="ticketBlock.active ? 'Lote ativo' : 'Lote inativo'"
            />
          </v-card>
          <v-btn @click="addTicketBlock" color="primary" text block small>
            <v-icon left small>mdi-plus</v-icon>
            Adicionar lote
          </v-btn>
        </div>
      </v-col>

      <v-col cols="6" class="pb-0 pt-0">
        <v-switch
          v-model="options.onlineSale"
          label="Venda online"
          :messages="[
            options.onlineSale
              ? 'Venda online permitida'
              : 'Venda online não permitida',
          ]"
        ></v-switch>
      </v-col>
      <v-col cols="6" class="pb-0 pt-0">
        <v-switch
          v-model="options.allowTransfer"
          label="Transferência de titularidade"
          :messages="[
            options.allowTransfer
              ? 'Transferência permitida'
              : 'Transferência não permitida',
          ]"
        ></v-switch>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";

export default {
  emits: ["input"],
  data: () => ({
    valid: false,
    options: {
      categories: [""],
      onlineSale: false,
      allowTransfer: false,
      ticketBlocks: [
        {
          name: "",
          startDate: "",
          endDate: "",
          prices: {},
          quantities: {},
          active: true,
        },
      ],
    },
    minQuantity: 1,
  }),
  methods: {
    addCategory() {
      this.options.categories.push("");
    },
    removeCategory(index) {
      this.options.categories.splice(index, 1);
    },
    addTicketBlock() {
      const lastTicketBlock =
        this.options.ticketBlocks[this.options.ticketBlocks.length - 1];

      const defaultTicketBlock = {
        prices: {},
        quantities: {},
        active: true, // Adicione a variável active aqui para cada lote
      };

      const nameRegex = /(.*?)(\d+)(.*)/;
      const match = lastTicketBlock.name.match(nameRegex);

      let newName;
      if (match) {
        const baseName = match[1];
        const number = parseInt(match[2], 10);
        const suffix = match[3];
        newName = `${baseName}${number + 1}${suffix}`;
      } else {
        newName = `${lastTicketBlock.name} 2`;
      }

      const newTicketBlock = Object.assign(defaultTicketBlock, {
        name: newName,
        startDate: lastTicketBlock?.startDate,
        endDate: lastTicketBlock?.endDate,
        categories: lastTicketBlock?.categories,
        active: lastTicketBlock?.active,
      });

      this.options.ticketBlocks.push(newTicketBlock);
    },
    removeTicketBlock(index) {
      this.options.ticketBlocks.splice(index, 1);
    },
    hasDuplicateCategory(value, index) {
      return (
        this.options.categories.filter(
          (category, i) =>
            category.toLowerCase() === value.toLowerCase() && i !== index
        ).length > 0
      );
    },
  },
  watch: {
    valid(val) {
      this.$emit("valid", val);
      if (val) {
        this.$emit("options", this.options);
      }
    },
  },
  computed: {
    dateRules() {
      return {
        start: [
          (v) => !!v || "Data de início é obrigatória",
          (v) => new Date(v).getFullYear() < 9999 || "Data de início inválida",
        ],
        end: (index) => [
          (v) => !!v || "Data de fim é obrigatória",
          (v) => new Date(v).getFullYear() < 9999 || "Data de fim inválida",
          (v) =>
            v >= this.options.ticketBlocks[index].startDate ||
            "Data de fim deve ser maior que a data de início",
        ],
      };
    },
  },
  mounted() {
    this.options.ticketBlocks[0].startDate =
      moment().format("YYYY-MM-DDTHH:mm");
    this.options.ticketBlocks[0].endDate = moment(this.party.endDate).format(
      "YYYY-MM-DDTHH:mm"
    );
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
